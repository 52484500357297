import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@common-src/model/form-control';
import { QueryPageModel, GroupQueryModel } from '@common-src/model/query-model';
import { GroupEntityType } from '@common-src/model/enum';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { dateFormat } from '@common-src/filter';
import { alarmRuleStatusList } from '@common-src/filter/alarm';
import LinkageConfigGroupService from '@common-src/service/linkage-config-group';

export const triggerTypeList = [
    { label: '设备触发', value: 'DEVICE' },
    { label: '定时触发', value: 'TIME' }
];

export const actionList = [
    // { label: '发送指令', value: 'COMMAND' },
    { label: '设备输出', value: 'DEVICESERVICE' },
    { label: '告警', value: 'ALARM' }
];

export class LinkageConfigEntityModel extends BaseEntityModel {
    @FormControl({
        label: '规则名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '分组',
        type: FormControlType.TREE_SELECT,
        optionsPromise: LinkageConfigGroupService.getGroupTree,
        optionsPromiseParam: GroupEntityType.LINKAGECONFIGE,
        required: true
    } as FormControlOptionModel)
    groupId: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    enable: boolean = undefined;
    type: string = 'ALARM';

    static getTableColumns() {
        return [
            {
                title: '规则名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 200
            },
            {
                title: '描述',
                dataIndex: 'remark',
                scopedSlots: { customRender: 'remark' },
                width: 200
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime',
                customRender: (text, record, index) => {
                    return dateFormat(text);
                },
                width: 160
            },
            {
                title: '运行状态',
                dataIndex: 'enable',
                scopedSlots: { customRender: 'enable' },
                width: 80
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 310
            }
        ];
    }

    static getTableHistoryColumns() {
        return [
            {
                title: '规则名称',
                dataIndex: 'ruleName',
                width: 100
            },
            {
                title: '设备名称',
                dataIndex: 'deviceName',
                width: 100
            },
            {
                title: '触发类型',
                dataIndex: 'triggerType',
                width: 100,
                customRender: (text, record, index) => {
                    switch (text) {
                        case 'DEVICE_ATTRIBUTE':
                            return '属性触发';
                        case 'DEVICE_EVENT':
                            return '事件触发';
                        case 'DEVICE_ATTRIBUTE_CALC':
                            return '属性计算触发';
                        case 'TIME':
                            return '定时触发';
                    }
                }
            },
            {
                title: '触发时间',
                dataIndex: 'triggerTime',
                width: 180,
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            },
            {
                title: '执行类型',
                dataIndex: 'actionType',
                width: 100,
                customRender: (text, record, index) => {
                    return _.get(_.find(actionList, item => item.value === text), 'label');
                }
            },
            {
                title: '备注',
                dataIndex: 'actionResult',
                scopedSlots: { customRender: 'actionResult' }
            },
            {
                title: '执行结果',
                dataIndex: 'result',
                width: 100,
                customRender: (text, record, index) => {
                    switch (text) {
                        case 'PASS':
                            return '成功';
                        case 'FAILED':
                            return '失败';
                        case 'UNCERTAIN':
                            return '未知';
                    }
                }
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime',
                width: 180,
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    static getTableActionLogColumns() {
        return [
            {
                title: '日志ID',
                dataIndex: 'detailId',
                width: 200
            },
            {
                title: '名称',
                dataIndex: 'detailName',
                width: 120
            },
            // {
            //     title: '结果',
            //     dataIndex: 'result',
            //     scopedSlots: { customRender: 'actionResult' }
            // },
            {
                title: '执行结果',
                dataIndex: 'result',
                width: 100,
                customRender: (text, record, index) => {
                    switch (text) {
                        case 'SUCCESSFUL':
                            return '成功';
                        case 'FAILED':
                            return '失败';
                        case 'EXPIRED':
                            return '消息过期';
                    }
                }
            },
            {
                title: '描述',
                dataIndex: 'remark',
                width: 120
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                width: 180,
                customRender: (text, record, index) => {
                    return dateFormat(text);
                }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class LinkageConfigQueryModel extends GroupQueryModel {
    @QueryControl({
        label: '运行状态',
        type: QueryControlType.SELECT,
        options: alarmRuleStatusList,
        hasAllOption: true
    })
    enable: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '规则名称',
        type: QueryControlType.TEXT,
        span: 8
    })
    name: string = undefined;

    type: string = 'ALARM';

    toService() {
        return super.getParams();
    }
}

export class LinkageConfigHistoryQueryModel extends QueryPageModel {
    constructor(ruleId: string) {
        super();
        this.ruleId = ruleId;
    }
    ruleId: string = undefined;
    @QueryControl({
        label: '触发类型',
        type: QueryControlType.SELECT,
        options: [
            { name: '属性触发', value: 'DEVICE_ATTRIBUTE' },
            { name: '属性计算触发', value: 'DEVICE_ATTRIBUTE_CALC' },
            { name: '事件触发', value: 'DEVICE_EVENT' },
            { name: '定时触发', value: 'TIME' }
        ],
        hasAllOption: true
    })
    triggerType: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '执行动作类型',
        type: QueryControlType.SELECT,
        options: _.map(actionList, item => ({ name: item.label, value: item.value })),
        hasAllOption: true
    })
    actionType: string = JTL.CONSTANT.ALL_OPTION.value;

    toService() {
        return super.getParams();
    }
}
