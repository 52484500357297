























































import { BaseComponent } from '@common-src/mixins/base-component';
import { Component, Prop } from 'vue-property-decorator';
import CommonService from '@common-src/service/common';
import { PropertyEntityType } from '@common-src/model/enum';
import SelectUserComponent from '@common-src/components/jtl-form/select-user.vue';

// const NotifyTypeList = [
//     { title: '微信小程序', value: 'APPLETS' },
//     { title: '3D大屏', value: 'TVWALL' }
// ];
const commandTypeList = [
    { label: '打开摄像头', value: 'OPENCAMER' },
    { label: '高亮显示', value: 'HIGHLIGHT' }
];

@Component({
    components: {
        SelectUserComponent
    }
})
export default class LinkageAlarmConfigComponent extends BaseComponent {
    @Prop()
    model: any;
    @Prop()
    deviceLinkList: Array<any>;
    gradeList: Array<any> = null;
    // get NotifyTypeList() {
    //     return NotifyTypeList;
    // }
    get CommandTypeList() {
        return commandTypeList;
    }
    channelTypeList: Array<any> = [];
    templateList: Array<any> = [];
    mounted() {
        CommonService.getPropertyOptions(PropertyEntityType.EVENT_GRADE).then(res => {
            this.gradeList = _.map(res, item => ({
                label: item.name,
                title: item.name,
                value: item.value
            }));
        });
        // // 消息渠道
        // CommonService.getChannelEmbedList().then(res => {
        //     this.channelTypeList = _.map(res, item => {
        //         return {
        //             label: item.name,
        //             title: item.name,
        //             value: item.value
        //         };
        //     });
        // });
        // // 消息模板
        CommonService.getTemplateList().then(res => {
            this.templateList = _.map(res, item => {
                return {
                    label: item.name,
                    title: item.name,
                    value: item.value
                };
            });
        });
    }
    // changeChannelType(e) {
    //     CommonService.getTemplateList(e).then(res => {
    //         this.model.templateId = '';
    //         this.templateList = _.map(res, item => {
    //             return {
    //                 label: item.name,
    //                 title: item.name,
    //                 value: item.value
    //             };
    //         });
    //     });
    // }
}

