










































import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { LinkageConfigEntityModel, LinkageConfigHistoryQueryModel } from '@common-src/entity-model/linkage-config-entity';
import LinkageConfigService from '@common-src/service/linkage-config';

@Component
export default class LinkageConfigActionLogComponent extends Mixins(TableComponent, DrawerComponent) {
    created() {
        this.isAutoQuery = false;
        this.initTable({
            listFunc: LinkageConfigService.getActionHistory,
            queryModel: {
                params: {
                    actionLogId: null
                }
            },
            tableColumns: LinkageConfigEntityModel.getTableActionLogColumns()
        });
    }
    drawerShow(model: any) {
        this.drawerOpen(null, `执行动作日志(${model.ruleName})`);
        (this.queryModel as any).params.actionLogId = model.id;
        this.getList();
    }
}

