














































import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { LinkageConfigEntityModel, LinkageConfigHistoryQueryModel } from '@common-src/entity-model/linkage-config-entity';
import LinkageConfigService from '@common-src/service/linkage-config';
import LinkageConfigActionLogComponent from './linkage-config-action-log.vue';

@Component({
    components: {
        LinkageConfigActionLogComponent
    }
})
export default class LinkageConfigHistoryComponent extends Mixins(TableComponent, DrawerComponent) {
    drawerShow(model: LinkageConfigEntityModel) {
        this.drawerOpen(null, `规则日志(${model.name})`);
        this.initTable({
            listFunc: LinkageConfigService.getHistory,
            queryModel: new LinkageConfigHistoryQueryModel(model.id),
            tableColumns: LinkageConfigEntityModel.getTableHistoryColumns()
        });
        this.getList();
    }

    logClick(model) {
        (this.$refs.linkageConfigActionLogDrawer as LinkageConfigActionLogComponent).drawerShow(model);
    }
}

