
















































































































































































import { BaseComponent } from '@common-src/mixins/base-component';
import { Component, Prop } from 'vue-property-decorator';
import DeviceService from '@common-src/service/device';
import DeviceTypeService from '@common-src/service3/device-type';
import { ThingsModelTypeModel } from '@common-src/entity-model/things-model-type-entity';
import { AttributeType, ReadWriteEnum, ThingsModelType } from '@common-src/model/enum';
import LinkageDeviceSelectDialog from './linkage-device-select.dialog.vue';

const deviceTypeService = new DeviceTypeService();

const ATTRIBUTE = 'DEVICE_ATTRIBUTE';
const EVENT = 'DEVICE_EVENT';
const ATTRIBUTE_CALC = 'DEVICE_ATTRIBUTE_CALC';
const triggerMethodList = [
    { label: '属性触发', value: ATTRIBUTE },
    { label: '事件触发', value: EVENT },
    { label: '属性计算触发', value: ATTRIBUTE_CALC }
];
const CALC_TYPE = [
    { label: '加', value: 'ADD' },
    { label: '减', value: 'SUB' },
    { label: '乘', value: 'MULTIPLY' },
    { label: '除', value: 'DIVIDE' }
];
const rpcTypeList = [
    { label: '属性调用', value: 'ATTR' },
    { label: '服务调用', value: 'SERVICE' }
];
const PeriodTimeList = [
    { title: '1分钟', value: 60 },
    { title: '2分钟', value: 120 },
    { title: '3分钟', value: 180 },
    { title: '4分钟', value: 240 },
    { title: '5分钟', value: 300 },
    { title: '10分钟', value: 600 },
    { title: '15分钟', value: 900 },
    { title: '20分钟', value: 1200 },
    { title: '30分钟', value: 1800 }
];

@Component({
    components: {
        LinkageDeviceSelectDialog
    }
})
export default class LinkageDeviceSelectComponent extends BaseComponent {
    @Prop()
    model: any;
    @Prop()
    deviceTypeList: Array<any>;
    @Prop()
    hasTriggerType: boolean;
    @Prop()
    hasMethod: boolean;
    @Prop()
    hasDuration: boolean;
    @Prop({ default: 'default' })
    deviceMode: string;

    ATTRIBUTE = ATTRIBUTE;
    EVENT = EVENT;
    ATTRIBUTE_CALC = ATTRIBUTE_CALC;

    clientAttributeList: Array<any> = null;
    clientAndWriteableAttributeList: Array<any> = null;

    get TriggerMethodList() {
        return triggerMethodList;
    }
    get RpcTypeList() {
        return rpcTypeList;
    }
    get CalcType() {
        return CALC_TYPE;
    }
    get OperateList() {
        let operateList = null;
        const attributeType = _.get(this.model, 'attributeModel.thingsModelType');
        switch (attributeType) {
            case ThingsModelType.ENUM:
                operateList = [
                    { label: '==', value: 'EQ' },
                    { label: '!=', value: 'NOTEQ' },
                    { label: 'in', value: 'IN' }
                ];
                break;
            case ThingsModelType.STRING:
            case ThingsModelType.PASSWORD:
            case ThingsModelType.TEXT:
                operateList = [
                    { label: '==', value: 'EQ' },
                    { label: '!=', value: 'NOTEQ' },
                    { label: 'contain', value: 'CONTAIN' }
                ];
                break;
            case ThingsModelType.BOOLEAN:
                operateList = [
                    { label: '==', value: 'EQ' },
                    { label: '!=', value: 'NOTEQ' }
                ];
                break;
            case ThingsModelType.DOUBLE:
            case ThingsModelType.INT:
            case ThingsModelType.FLOAT:
            case ThingsModelType.DATE:
                operateList = [
                    { label: '>', value: 'GT' },
                    { label: '>=', value: 'GTE' },
                    { label: '<', value: 'LT' },
                    { label: '<=', value: 'LTE' },
                    { label: '==', value: 'EQ' },
                    { label: '!=', value: 'NOTEQ' },
                    { label: 'between', value: 'BTW' }
                ];
                break;
            default:
                operateList = [
                    { label: '>', value: 'GT' },
                    { label: '>=', value: 'GTE' },
                    { label: '<', value: 'LT' },
                    { label: '<=', value: 'LTE' },
                    { label: '==', value: 'EQ' },
                    { label: '!=', value: 'NOTEQ' },
                    { label: 'in', value: 'IN' },
                    { label: 'between', value: 'BTW' },
                    { label: 'contain', value: 'CONTAIN' }
                ];
                break;
        }
        return operateList;
    }
    get PeriodTimeList() {
        return PeriodTimeList;
    }
    get RpcTypeSelectMethodInputData() {
        const selectedMethodModel = _.find(this.model.methodList, item => item.value === this.model.method);
        const inputData = _.get(selectedMethodModel, 'inputData');
        if (inputData && inputData.length > 1) {
            // 目前只支持1个参数
            return [inputData[0]];
        }
        return inputData;
    }

    mounted() {
        this.onDeviceTypeChange(this.model?.product, false)?.then(() => {
            this.onDeviceAttributeChange(this.model?.attribute, false);
        });
    }

    async onDeviceTypeChange(deviceTypeId: string, clearValue: boolean = true) {
        if (deviceTypeId) {
            if (clearValue) {
                this.model.device = [];
                this.model.triggerType = undefined;
                this.model.event = undefined;
                this.model.method = undefined;
                this.model.attribute = undefined;
                this.model.attributeModel = undefined;
                this.model.operate = undefined;
                this.model.value = {};
                this.model.duration = undefined;
                this.model.periodTime = undefined;
                this.model.count = undefined;
                this.model.rpcType = undefined;
                this.model.targetDevId = undefined;
                this.model.calcType = undefined;
                this.model.waitTime = undefined;
                this.rpcTypeChange();
            }
            DeviceService.getDeviceListByTypeId(deviceTypeId).then(res => {
                this.model.deviceList = res;
                this.$forceUpdate();
            });
            return deviceTypeService.retrieve(deviceTypeId).then(res => {
                this.model.eventList = _.map(_.get(res, 'events'), item => {
                    return {
                        label: `${item.name}|${item.identifier}`,
                        title: `${item.name}|${item.identifier}`,
                        value: `${item.id}|${item.identifier}`
                    };
                });
                this.model.attributeList = _.map(_.get(res, 'attrs'), item => {
                    return {
                        label: item.name,
                        title: item.name,
                        value: item.identifier,
                        attributeModel: item.thingsModelTypeModel as ThingsModelTypeModel
                    };
                });
                this.clientAttributeList = _.map(_.filter(_.get(res, 'attrs'), item => item.attributeType === AttributeType.CLIENT), item => {
                    return {
                        label: item.name,
                        title: item.name,
                        value: item.identifier,
                        attributeModel: item.thingsModelTypeModel as ThingsModelTypeModel
                    };
                });
                this.clientAndWriteableAttributeList = _.map(
                    _.filter(_.get(res, 'attrs'), item => item.attributeType === AttributeType.CLIENT && item.accessMode === ReadWriteEnum.READ_WRITE
                    ), item => {
                        return {
                            label: item.name,
                            title: item.name,
                            value: item.identifier,
                            accessMode: item.accessMode,
                            attributeType: item.attributeType,
                            attributeModel: item.thingsModelTypeModel as ThingsModelTypeModel
                        };
                    });
                if (this.hasMethod) {
                    this.model.methodList = _.map(_.get(res, 'methods'), item => {
                        return {
                            label: item.name,
                            title: item.name,
                            value: item.identifier,
                            inputData: item.inputData
                        };
                    });
                }
                this.$forceUpdate();
            });
        }
    }

    onDeviceAttributeChange(attributeIdentifier: string, clearValue: boolean = true) {
        if (clearValue) {
            this.model.operate = undefined;
            this.model.value = {};
            this.model.inputData = undefined;
        }
        const attribute = _.find(this.clientAttributeList, item => item.value === attributeIdentifier);
        if (attribute) {
            this.model.attributeModel = attribute.attributeModel;
            if (
                this.model.attributeModel.thingsModelType === ThingsModelType.ENUM &&
                this.model.operate !== 'IN' &&
                (!this.hasTriggerType || this.model.triggerType === ATTRIBUTE)
            ) {
                // 不是IN的枚举值，从数组转成对象
                this.model.value = {
                    val: _.get(this.model.value, 'val.[0]')
                };
            }
            this.$forceUpdate();
        }
    }

    rpcTypeChange() {
        this.model.attribute = undefined;
        this.model.method = undefined;
        this.model.inputData = undefined;
    }

    selectMore() {
        if (!this.model.product) {
            this.showMessageWarning('请先选择产品');
            return;
        }
        (this.$refs.selectDeviceDialog as LinkageDeviceSelectDialog).dialogOpen(this.model.product, this.model.device);
    }

    selectDeviceDialogOK(selectDeviceIds: Array<string>) {
        this.model.device = selectDeviceIds;
    }
}

