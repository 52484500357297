















































































































































































import { Component, Watch } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { LinkageConfigEntityModel, triggerTypeList, actionList } from '@common-src/entity-model/linkage-config-entity';
import CommonService from '@common-src/service/common';
import LinkageGroupService from '@common-src/service/linkage-group';
import { GroupEntityType, ThingsModelType, PropertyEntityType } from '@common-src/model/enum';
import LinkageConfigService from '@common-src/service/linkage-config';
import LinkageDeviceSelectComponent from './linkage-device-select.vue';
import LinkageAlarmConfigComponent from './linkage-alarm-config.vue';
import moment from 'moment';
import { POSITIVE_INT_REGEXP } from '@common-src/model/regexp';
import { ModuleType } from '@common-src/enum/module-type.enum';

const TIME = 'TIME';
const ATTRIBUTE = 'DEVICE_ATTRIBUTE';
const EVENT = 'DEVICE_EVENT';
const ATTRIBUTE_CALC = 'DEVICE_ATTRIBUTE_CALC';
const conditionTypeList = [
    { label: '属性条件', value: ATTRIBUTE },
    { label: '时间范围', value: 'TIMESPAN' }
];
const conditionOperatorList = [
    { label: 'AND', value: 'AND' },
    { label: 'OR', value: 'OR' }
];
const deviceRange = [
    { label: '其他设备', value: 'OTHER' },
    { label: '触发器设备', value: 'TRIGGER' }
];
@Component({
    components: {
        LinkageDeviceSelectComponent,
        LinkageAlarmConfigComponent
    }
})
export default class LinkageConfigDrawer extends DrawerComponent {
    linkageConfigModel: LinkageConfigEntityModel = null;
    triggerList: Array<any> = [];
    conditionList: Array<any> = [];
    actionList: Array<any> = [];
    deviceTypeList: Array<any> = null;
    alarmTypeList: Array<any> = null;
    deviceTypeFilterList: Array<any> = null;
    deviceLinkList: Array<any> = null;
    ATTRIBUTE = ATTRIBUTE;
    EVENT = EVENT;
    TIME = TIME;
    ATTRIBUTE_CALC= ATTRIBUTE_CALC;

    created() {
        CommonService.getPropertyOptions(PropertyEntityType.ALARM_TYPE).then(res => {
            this.alarmTypeList = _.map(res, item => {
                return { label: item.name, value: item.code };
            });
        });
        CommonService.getDeviceTypeOptions().then(res => {
            this.deviceTypeList = _.map(res, item => {
                return { label: item.name, value: item.value, title: item.name };
            });
        });
        if (JTL.moduleType !== ModuleType.EDGE) {
            LinkageGroupService.getGroupTree(GroupEntityType.LINKAGE).then(res => {
                this.deviceLinkList = res;
            });
        }
    }

    get TriggerTypeList() {
        return triggerTypeList;
    }
    get ConditionTypeList() {
        return conditionTypeList;
    }
    get ActionList() {
        return actionList;
    }
    get DeviceRange() {
        return deviceRange;
    }

    get ConditionOperatorList() {
        return conditionOperatorList;
    }

    @Watch('triggerList', { deep: true })
    handleDeviceTypeFilter() {
        const projectIds = _.map(this.triggerList, item => item.product);
        this.deviceTypeFilterList = this._.filter(this.deviceTypeList, el => projectIds?.find(o => o === el.value));
    }

    drawerShow(model: LinkageConfigEntityModel) {
        this.linkageConfigModel = model;
        this.drawerOpen(null, `规则设计(${model.name})`);
        LinkageConfigService.retrieve(model.id).then(res => {
            this.triggerList = _.map(_.get(res, 'triggerList'), item => {
                if (item.type === TIME) {
                    return {
                        id: item.id,
                        type: TIME,
                        cron: _.get(item, 'triggerParam.dateTimeExpression'),
                        alarmRemark: _.get(item, 'triggerParam.alarmRemark'),
                        device: [],
                        triggerType: null,
                        value: {},
                        event: null,
                        attribute: null,
                        operate: null,
                        duration: null,
                        periodTime: null,
                        count: null,
                        attributeList: null,
                        deviceList: null,
                        eventList: null,
                        attributeModel: null,
                        calcType: null,
                        targetDevId: null,
                        waitTime: null
                    };
                }
                let event = null;
                if (_.get(item, 'triggerParam.eventId')) {
                    event = `${_.get(item, 'triggerParam.eventId')}|${_.get(item, 'triggerParam.eventIdentifier')}`;
                } else {
                    event = undefined;
                }
                const triggerItem = {
                    id: item.id,
                    type: 'DEVICE',
                    product: item.productId,
                    device: item.deviceIds,
                    triggerType: item.type,
                    // event: item.eventId ? `${item.eventId}|${item.eventIdentifier}` : undefined,
                    // attribute: item.attributeKey,
                    // operate: item.compareType,
                    value: item.attrThreshold || {},
                    // duration: item.duration,
                    // periodTime: item.periodTime,
                    // count: item.count,
                    event,
                    attribute: _.get(item, 'triggerParam.attributeKey'),
                    operate: _.get(item, 'triggerParam.compareType'),
                    // value: _.get(item, 'triggerParam.attrThreshold') || {},
                    duration: _.get(item, 'triggerParam.duration'),
                    periodTime: _.get(item, 'triggerParam.periodTime'),
                    count: _.get(item, 'triggerParam.count'),
                    alarmRemark: _.get(item, 'triggerParam.alarmRemark'),
                    attributeList: null,
                    deviceList: null,
                    eventList: null,
                    attributeModel: null,
                    calcType: _.get(item, 'triggerParam.calcType'),
                    targetDevId: _.get(item, 'triggerParam.targetDevId'),
                    waitTime: _.get(item, 'triggerParam.waitTime')
                };
                return triggerItem;
            });

            this.conditionList = _.map(_.get(res, 'conditionList'), item => {
                const conditionItem = {
                    id: item.id,
                    type: item.type,
                    triggerType: ATTRIBUTE,
                    product: item.productId,
                    device: item.deviceIds,
                    deviceType: item.deviceType,
                    value: item.attrThreshold || {},
                    attribute: _.get(item, 'conditionParam.attributeKey'),
                    operate: _.get(item, 'conditionParam.compareType'),
                    operator: _.get(item, 'conditionParam.operator') || 'AND',
                    startTime: moment(item.attrThreshold?.startTime),
                    endTime: moment(item.attrThreshold?.endTime),
                    deviceList: null,
                    attributeList: null,
                    attributeModel: null
                };
                return conditionItem;
            });

            this.actionList = _.map(_.filter(_.get(res, 'actionList'), item => item.type !== 'COMMAND'), item => {
                // if (item.type === 'COMMAND') {
                //     return { id: item.id, type: item.type, commandType: _.get(item, 'actionParam.commandType'), tvWallDeviceGroupId: _.get(item, 'actionParam.tvWallDeviceGroupId'), actionParam: {} };
                // } else
                if (item.type === 'DEVICESERVICE') {
                    const actionParamInputData = _.get(item, 'actionParam.param');
                    const actionItem = {
                        id: item.id,
                        type: item.type,
                        deviceType: item.deviceType,
                        product: _.get(item, 'actionParam.productId'),
                        device: _.get(item, 'actionParam.deviceIds'),
                        rpcType: _.get(item, 'actionParam.rpcType'),
                        attribute: _.get(item, 'actionParam.attrIdentifier'),
                        method: _.get(item, 'actionParam.serviceIdentifier'),
                        inputData: typeof (actionParamInputData) === 'object' ? JSON.stringify(actionParamInputData) : `${actionParamInputData}`,
                        deviceList: null,
                        methodList: null,
                        actionParam: {}
                    };
                    return actionItem;
                }
                return {
                    id: item.id,
                    type: item.type,
                    actionParam: {
                        cycle: (_.get(item, 'actionParam.cycle') / 60) || null,
                        noticeList: _.map(_.get(item, 'actionParam.notice'), noticeItem => {
                            return {
                                id: noticeItem.id,
                                name: noticeItem.name,
                                isUser: _.get(item, 'actionParam.notifyUserType') === 'SINGLE' ? true : false
                            };
                        }),
                        grade: _.get(item, 'actionParam.grade'),
                        notifyPeriod: (_.get(item, 'actionParam.notifyPeriod') / 60) || null,
                        // notifyType: _.get(item, 'actionParam.notifyType')?.split(','),
                        commandType: _.get(item, 'actionParam.commandType'),
                        tvWallDeviceGroupId: _.get(item, 'actionParam.tvWallDeviceGroupId'),
                        templateIds: _.get(item, 'actionParam.templateIds'),
                        createReport: _.get(item, 'actionParam.createReport')
                    },
                    product: null,
                    device: undefined,
                    rpcType: null,
                    attribute: null,
                    method: null,
                    inputData: null,
                    deviceList: null,
                    methodList: null
                };
            });

            if (!this.triggerList || this.triggerList.length === 0) {
                this.addNewTrigger();
            }
            // if (!this.conditionList || this.conditionList.length === 0) {
            //     this.addNewCondition();
            // }
            if (!this.actionList || this.actionList.length === 0) {
                this.addNewAction();
            }
        });
    }
    addNewTrigger() {
        this.triggerList.push({
            type: undefined,
            cron: undefined,
            product: undefined,
            device: undefined,
            triggerType: undefined,
            attribute: undefined,
            event: undefined,
            operate: undefined,
            alarmRemark: undefined,
            value: {},
            deviceList: null,
            eventList: null,
            attributeList: null,
            attributeModel: null,
            duration: undefined,
            periodTime: undefined,
            count: undefined,
            calcType: undefined,
            targetDevId: undefined,
            waitTime: undefined
        });
    }
    removeTrigger(index: number) {
        this.triggerList.splice(index, 1);
    }
    addNewCondition() {
        this.conditionList.push({
            type: undefined,
            product: undefined,
            device: undefined,
            attribute: undefined,
            operate: undefined,
            deviceType: undefined,
            operator: 'AND',
            value: {},
            startTime: null,
            endTime: null,
            attributeModel: null,
            deviceList: null,
            attributeList: null
        });
    }
    removeCondition(index: number) {
        this.conditionList.splice(index, 1);
    }
    addNewAction() {
        this.actionList.push({
            type: undefined,
            actionType: undefined,
            commandType: undefined,
            tvWallDeviceGroupId: undefined,
            product: undefined,
            device: undefined,
            deviceType: undefined,
            rpcType: undefined,
            attribute: undefined,
            method: undefined,
            inputData: undefined,
            deviceList: null,
            attributeList: null,
            methodList: null,
            actionParam: {}
        });
    }
    removeAction(index: number) {
        this.actionList.splice(index, 1);
    }

    saveClick() {
        let params: any = { id: this.linkageConfigModel.id };
        try {
            if (!this.triggerList || this.triggerList.length === 0) {
                throw new Error('请配置触发器！');
            }
            params.triggerConfigList = _.map(this.triggerList, (item, index) => {
                if (!item.type) {
                    throw new Error(`触发器${index + 1}-请选择触发器类型！`);
                }
                if (item.type === TIME) {
                    if (!item.cron) {
                        throw new Error(`触发器${index + 1}-请输入cron时间表达式！`);
                    }
                    return {
                        id: item.id,
                        type: TIME,
                        // dateTimeExpression: item.cron,
                        triggerParam: {
                            dateTimeExpression: item.cron,
                            alarmRemark: item.alarmRemark
                        }
                    };
                } else if (item.type === 'DEVICE') {
                    if (!item.product) {
                        throw new Error(`触发器${index + 1}-请选择产品！`);
                    }
                    if (!item.triggerType) {
                        throw new Error(`触发器${index + 1}-请选择触发方式！`);
                    }
                    if (item.triggerType === ATTRIBUTE) {
                        if (!item.attribute) {
                            throw new Error(`触发器${index + 1}-请选择属性！`);
                        }
                        if (!item.operate) {
                            throw new Error(`触发器${index + 1}-请选择比较模式！`);
                        }
                        if (item.operate === 'BTW') {
                            if (_.isEmpty(item.value?.min)) {
                                throw new Error(`触发器${index + 1}-请输入最小值！`);
                            }
                            if (_.isEmpty(item.value?.max)) {
                                throw new Error(`触发器${index + 1}-请输入最大值！`);
                            }
                        } else if (_.isEmpty(item.value?.val)) {
                            throw new Error(`触发器${index + 1}-请选择比较值！`);
                        }
                        const dataType = _.get(_.find(item.attributeList, attrItem => attrItem.value === item.attribute), 'attributeModel.thingsModelType');
                        let attrThresholdValue = null;
                        if (dataType === ThingsModelType.ENUM && item.operate !== 'IN') {
                            attrThresholdValue = {
                                val: [item.value.val]
                            };
                        } else {
                            attrThresholdValue = item.value;
                        }
                        if (_.isEmpty(item.duration)) {
                            throw new Error(`触发器${index + 1}-请输入持续时间！`);
                        }
                        return {
                            id: item.id,
                            type: item.triggerType,
                            productId: item.product,
                            deviceIds: item.device,
                            attrThreshold: attrThresholdValue,
                            triggerParam: {
                                attributeKey: item.attribute,
                                compareType: item.operate,
                                dataType,
                                duration: item.duration,
                                alarmRemark: item.alarmRemark
                            }
                        };
                    } else if (item.triggerType === ATTRIBUTE_CALC) {
                        if (!item.attribute) {
                            throw new Error(`触发器${index + 1}-请选择属性！`);
                        }
                        if (!item.device || item.device.length !== 1) {
                            throw new Error(`触发器${index + 1}-属性计算目前只支持选择一个设备`);
                        }
                        if (!item.calcType) {
                            throw new Error(`触发器${index + 1}-请选择计算规则！`);
                        }
                        if (!item.targetDevId) {
                            throw new Error(`触发器${index + 1}-请选择比较设备！`);
                        }
                        if (_.isEmpty(item.waitTime)) {
                            throw new Error(`触发器${index + 1}-请输入有效时间！`);
                        }
                        if (!item.operate) {
                            throw new Error(`触发器${index + 1}-请选择比较模式！`);
                        }
                        if (item.operate === 'BTW') {
                            if (_.isEmpty(item.value?.min)) {
                                throw new Error(`触发器${index + 1}-请输入最小值！`);
                            }
                            if (_.isEmpty(item.value?.max)) {
                                throw new Error(`触发器${index + 1}-请输入最大值！`);
                            }
                        } else if (_.isEmpty(item.value?.val)) {
                            throw new Error(`触发器${index + 1}-请选择比较值！`);
                        }
                        const dataType = _.get(_.find(item.attributeList, attrItem => attrItem.value === item.attribute), 'attributeModel.thingsModelType');
                        let attrThresholdValue = null;
                        if (dataType === ThingsModelType.ENUM && item.operate !== 'IN') {
                            attrThresholdValue = {
                                val: [item.value.val]
                            };
                        } else {
                            attrThresholdValue = item.value;
                        }
                        if (_.isEmpty(item.duration)) {
                            throw new Error(`触发器${index + 1}-请输入持续时间！`);
                        }
                        return {
                            id: item.id,
                            type: item.triggerType,
                            productId: item.product,
                            deviceIds: item.device,
                            attrThreshold: attrThresholdValue,
                            triggerParam: {
                                attributeKey: item.attribute,
                                compareType: item.operate,
                                dataType,
                                duration: item.duration,
                                alarmRemark: item.alarmRemark,
                                calcType: item.calcType,
                                targetDevId: item.targetDevId,
                                waitTime: item.waitTime
                            }
                        };
                    } else if (item.triggerType === EVENT) {
                        if (!item.event) {
                            throw new Error(`触发器${index + 1}-请选择事件！`);
                        }
                        if (!item.periodTime) {
                            throw new Error(`触发器${index + 1}-请选择周期时间！`);
                        }
                        if (!POSITIVE_INT_REGEXP.test(item.count)) {
                            throw new Error(`触发器${index + 1}-发送次数填写错误！`);
                        }
                        const [eventId, eventIdentifier] = item.event?.split('|');
                        return {
                            id: item.id,
                            type: item.triggerType,
                            productId: item.product,
                            deviceIds: item.device,
                            triggerParam: {
                                eventId,
                                eventIdentifier,
                                compareType: item.operate,
                                dataType: _.get(_.find(item.attributeList, attrItem => attrItem.value === item.attribute), 'attributeModel.thingsModelType'),
                                periodTime: item.periodTime,
                                count: item.count,
                                alarmRemark: item.alarmRemark
                            }
                        };
                    }
                }
            });
            params.conditionConfigList = _.map(this.conditionList, (item, index) => {
                if (!item.type) {
                    throw new Error(`执行条件${index + 1}-请选择执行条件类型！`);
                }
                if (item.type === 'TIMESPAN') {
                    // if (!item.timeRange || item.timeRange.length !== 2) {
                    //     throw new Error(`执行条件${index + 1}-请选择时间范围！`);
                    // }
                    if (!item.startTime) {
                        throw new Error(`执行条件${index + 1}-请选择开始时间！`);
                    }
                    if (!item.endTime) {
                        throw new Error(`执行条件${index + 1}-请选择结束时间！`);
                    }
                    if (!item.operator) {
                        throw new Error(`执行条件${index + 1}-请选择执行操作！`);
                    }
                    return {
                        id: item.id,
                        type: 'TIMESPAN',
                        attrThreshold: {
                            startTime: item.startTime.toDate().getTime(),
                            endTime: item.endTime.toDate().getTime()
                        },
                        conditionParam: {
                            operator: item.operator
                        }
                    };
                }
                if (!item.product) {
                    throw new Error(`执行条件${index + 1}-请选择产品！`);
                }
                if (!item.attribute) {
                    throw new Error(`执行条件${index + 1}-请选择属性！`);
                }
                if (!item.operate) {
                    throw new Error(`执行条件${index + 1}-请选择比较模式！`);
                }
                if (!item.operator) {
                    throw new Error(`执行条件${index + 1}-请选择执行操作！`);
                }
                // if (!item.value) {
                //     throw new Error('请输入执行条件比较值');
                // }
                if (item.operate === 'BTW') {
                    if (_.isNil(item.value?.min)) {
                        throw new Error(`执行条件${index + 1}-请输入最小值！`);
                    }
                    if (_.isNil(item.value?.max)) {
                        throw new Error(`执行条件${index + 1}-请输入最大值！`);
                    }
                } else if (_.isNil(item.value?.val)) {
                    throw new Error(`执行条件${index + 1}-请选择比较值！`);
                }
                const dataType = _.get(_.find(item.attributeList, attrItem => attrItem.value === item.attribute), 'attributeModel.thingsModelType');
                const deviceTriggerIds = _.find(params.triggerConfigList, el => el.productId === item.product)?.deviceIds;
                const deviceIds = item.deviceType === 'TRIGGER' ? deviceTriggerIds : item.device;
                let attrThresholdValue = null;
                if (dataType === ThingsModelType.ENUM && item.operate !== 'IN') {
                    attrThresholdValue = {
                        val: [item.value.val]
                    };
                } else {
                    attrThresholdValue = item.value;
                }
                return {
                    id: item.id,
                    type: ATTRIBUTE,
                    productId: item.product,
                    deviceIds,
                    deviceType: item.deviceType,
                    // attributeKey: item.attribute,
                    // compareType: item.operate,
                    attrThreshold: attrThresholdValue,
                    // dataType: _.get(_.find(item.attributeList, attrItem => attrItem.value === item.attribute), 'attributeModel.thingsModelType'),
                    // duration: item.duration,
                    conditionParam: {
                        attributeKey: item.attribute,
                        compareType: item.operate,
                        operator: item.operator,
                        // attrThreshold: item.value,
                        dataType,
                        duration: item.duration
                    }
                };
            });

            if (!this.actionList || this.actionList.length === 0) {
                throw new Error('请配置执行动作！');
            }
            params.actionConfigList = _.map(this.actionList, (item, index) => {
                if (!item.type) {
                    throw new Error(`执行动作${index + 1}-请选择执行动作类型！`);
                }
                if (item.type === 'DEVICESERVICE') {
                    if (!item.product) {
                        throw new Error(`执行动作${index + 1}-请选择产品！`);
                    }
                    if (!item.rpcType) {
                        throw new Error(`执行动作${index + 1}-请选择调用方式！`);
                    }
                    if (item.rpcType === 'ATTR' && !item.attribute) {
                        throw new Error(`执行动作${index + 1}-请选择属性！`);
                    }
                    if (item.rpcType === 'SERVICE' && !item.method) {
                        throw new Error(`执行动作${index + 1}-请选择方法！`);
                    }
                    if (_.isNil(item.inputData)) {
                        throw new Error(`执行动作${index + 1}-请输入参数！`);
                    }
                    let inputParam = null;
                    try {
                        inputParam = JSON.parse(item.inputData);
                    } catch (e) {
                        inputParam = item.inputData;
                        console.warn(e);
                    }
                    const deviceTriggerIds = _.find(params.triggerConfigList, el => el.productId === item.product)?.deviceIds;
                    const deviceIds = item.deviceType === 'TRIGGER' ? deviceTriggerIds : item.device;
                    if (item.rpcType === 'ATTR') {
                        const dataType = _.get(_.find(item.attributeList, attrItem => attrItem.value === item.attribute), 'attributeModel.thingsModelType');
                        return {
                            id: item.id,
                            type: item.type,
                            deviceType: item.deviceType,
                            actionParam: {
                                productId: item.product,
                                deviceIds,
                                rpcType: item.rpcType,
                                attrIdentifier: item.attribute,
                                param: inputParam,
                                dataType
                            }
                        };
                    } else if (item.rpcType === 'SERVICE') {
                        return {
                            id: item.id,
                            type: item.type,
                            deviceType: item.deviceType,
                            actionParam: {
                                productId: item.product,
                                deviceIds,
                                rpcType: item.rpcType,
                                serviceIdentifier: item.method,
                                param: inputParam
                            }
                        };
                    }
                }
                if (!_.get(item, 'actionParam.grade')) {
                    throw new Error(`执行动作${index + 1}-请选择告警级别！`);
                }
                if (_.get(item, 'actionParam.cycle') <= 0) {
                    throw new Error(`执行动作${index + 1}-告警周期填写错误！`);
                }
                if (_.get(item, 'actionParam.notifyPeriod') <= 0) {
                    throw new Error(`执行动作${index + 1}-通知周期填写错误！`);
                }
                // if (!_.get(item, 'actionParam.notifyType')) {
                //     throw new Error(`执行动作${index + 1}-请选择通知方式！`);
                // }
                // if (_.get(item, 'actionParam.notifyType', []).indexOf('APPLETS') > -1 && _.isEmpty(_.get(item, 'actionParam.noticeList'))) {
                //     throw new Error(`执行动作${index + 1}-请选择通知对象！`);
                // }
                const commandType = _.get(item, 'actionParam.commandType');
                // if (commandType && commandType === 'OPENCAMER' && !_.get(item, 'actionParam.tvWallDeviceGroupId')) {
                //     throw new Error(`执行动作${index + 1}-请选择设备关系分组！`);
                // }
                return {
                    id: item.id,
                    type: item.type,
                    actionParam: {
                        grade: _.get(item, 'actionParam.grade'),
                        cycle: _.get(item, 'actionParam.cycle', 0) * 60,
                        notifyPeriod: _.get(item, 'actionParam.notifyPeriod', 0) * 60,
                        // notifyType: _.get(item, 'actionParam.notifyType', [])?.join(','),
                        commandType,
                        tvWallDeviceGroupId: _.get(item, 'actionParam.tvWallDeviceGroupId'),
                        entityIds: _.map(_.get(item, 'actionParam.noticeList'), noticeItem => noticeItem.id),
                        notifyUserType: _.get(item, 'actionParam.noticeList[0].isUser') ? 'SINGLE' : 'GROUP',
                        templateIds: _.get(item, 'actionParam.templateIds'),
                        createReport: _.get(item, 'actionParam.createReport')
                    }
                };
            });
        } catch (e) {
            this.showMessageWarning(_.get(e, 'message'));
            return;
        }

        return LinkageConfigService.saveConfig(params).then(res => {
            this.showMessageSuccess('保存成功');
            this.drawerClose();
        });
    }

    drawerClose() {
        this.linkageConfigModel = null;
        this.triggerList = [];
        this.conditionList = [];
        this.actionList = [];
        // this.deviceTypeList = null;
        // this.deviceLinkList = null;
        this.drawerVisible = false;
    }
}

