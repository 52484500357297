
import { LinkageConfigEntityModel, LinkageConfigHistoryQueryModel, LinkageConfigQueryModel } from '@common-src/entity-model/linkage-config-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${RULE_BASE_REQUEST_PATH}/rule/config`;

class LinkageConfigService implements ICRUDQ<LinkageConfigEntityModel, LinkageConfigQueryModel> {
    async create(model: LinkageConfigEntityModel):Promise<LinkageConfigEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<any> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        // return new LinkageConfigEntityModel().toModel(res);
        return res;
    }

    async update(model: LinkageConfigEntityModel):Promise<LinkageConfigEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: LinkageConfigEntityModel):Promise<LinkageConfigEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: LinkageConfigQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new LinkageConfigEntityModel().toModel(item));
        return res;
    }

    async getHistory(query?: LinkageConfigHistoryQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${RULE_BASE_REQUEST_PATH}/action/log/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        // res.items[0].actionResult = [
        //     { 'errMsg': '中台账号未在微信小程序授权', 'phone': '18900000001' },
        //     { 'errMsg': '中台账号未在微信小程序授权', 'phone': '18900000001' },
        //     { 'errMsg': '中台账号未在微信小程序授权', 'phone': '18900000001' },
        //     { 'errMsg': '中台账号未在微信小程序授权', 'phone': '18900000001' },
        //     { 'errMsg': '中台账号未在微信小程序授权', 'phone': '18900000001' }
        // ];
        return res;
    }

    async getActionHistory(query?: any, page?: number, limit?: number):Promise<any> {
        const url = `${RULE_BASE_REQUEST_PATH}/action/log/detail/list`;
        const params = Object.assign({ page, limit }, query);
        const res = await post(url, params);
        return res;
    }

    async enable(id: string, enable: boolean):Promise<any> {
        const url = `${URL_PATH}/enable`;
        const res = await post(url, { id, enable });
        return res;
    }

    async trigger(id: string):Promise<LinkageConfigEntityModel> {
        const url = `${URL_PATH}/trigger`;
        const res = await post(url, { id });
        return res;
    }

    async saveConfig(params: any) {
        const url = `${URL_PATH}/design`;
        const res = await post(url, params);
        return res;
    }

    async refresh():Promise<any> {
        const url = `${RULE_BASE_REQUEST_PATH}/tsrule/config/rule-engine/refresh`;
        const res = await get(url);
        return res;
    }
}

export default new LinkageConfigService();
