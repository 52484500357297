var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: "90%",
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "linkage-config-drawer" },
        [
          _c("a-card", { attrs: { bordered: true, title: "触发器" } }, [
            _c(
              "ul",
              [
                _vm._l(_vm.triggerList, function (item, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "linkage-config-item-wrapper" },
                    [
                      _c(
                        "a-row",
                        { attrs: { type: "flex", align: "middle" } },
                        [
                          _c(
                            "a-col",
                            {
                              staticClass: "linkage-config-item-content",
                              attrs: { span: 23 },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "linkage-config-item-title" },
                                [_vm._v("触发器" + _vm._s(index + 1))]
                              ),
                              _c("br"),
                              _c(
                                "div",
                                { staticClass: "linkage-config-item-nowrap" },
                                [
                                  _c("a-select", {
                                    attrs: {
                                      placeholder: "请选择触发器类型",
                                      options: _vm.TriggerTypeList,
                                    },
                                    model: {
                                      value: item.type,
                                      callback: function ($$v) {
                                        _vm.$set(item, "type", $$v)
                                      },
                                      expression: "item.type",
                                    },
                                  }),
                                  item.type === _vm.TIME
                                    ? [
                                        _c("jtl-cron-component", {
                                          staticClass: "cron-value",
                                          model: {
                                            value: item.cron,
                                            callback: function ($$v) {
                                              _vm.$set(item, "cron", $$v)
                                            },
                                            expression: "item.cron",
                                          },
                                        }),
                                        _c("a-select", {
                                          attrs: {
                                            placeholder: "请选择告警类型",
                                            options: _vm.alarmTypeList,
                                          },
                                          model: {
                                            value: item.alarmRemark,
                                            callback: function ($$v) {
                                              _vm.$set(item, "alarmRemark", $$v)
                                            },
                                            expression: "item.alarmRemark",
                                          },
                                        }),
                                      ]
                                    : item.type === "DEVICE"
                                    ? _c(
                                        "LinkageDeviceSelectComponent",
                                        {
                                          attrs: {
                                            model: item,
                                            "device-type-list":
                                              _vm.deviceTypeList,
                                            "has-trigger-type": true,
                                            "has-duration": true,
                                            "device-mode": "multiple",
                                          },
                                        },
                                        [
                                          _c("a-select", {
                                            attrs: {
                                              placeholder: "请选择告警类型",
                                              options: _vm.alarmTypeList,
                                            },
                                            model: {
                                              value: item.alarmRemark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "alarmRemark",
                                                  $$v
                                                )
                                              },
                                              expression: "item.alarmRemark",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "a-col",
                            { staticClass: "text-center", attrs: { span: 1 } },
                            [
                              index > 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "jtl-link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeTrigger(index)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c("li", { staticClass: "linkage-config-item-wrapper" }, [
                  _c(
                    "a",
                    {
                      staticClass: "jtl-link",
                      on: { click: _vm.addNewTrigger },
                    },
                    [_vm._v("+ 新增触发器")]
                  ),
                ]),
              ],
              2
            ),
          ]),
          _c("br"),
          _c(
            "a-card",
            { attrs: { bordered: true, title: "执行条件 (支持空条件)" } },
            [
              _c(
                "ul",
                [
                  _vm._l(_vm.conditionList, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "linkage-config-item-wrapper",
                      },
                      [
                        _c(
                          "a-row",
                          { attrs: { type: "flex", align: "middle" } },
                          [
                            _c(
                              "a-col",
                              {
                                staticClass: "linkage-config-item-content",
                                attrs: { span: 23 },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "linkage-config-item-title" },
                                  [_vm._v("执行条件" + _vm._s(index + 1))]
                                ),
                                _c("br"),
                                _c(
                                  "div",
                                  { staticClass: "linkage-config-item-nowrap" },
                                  [
                                    _c("a-select", {
                                      attrs: {
                                        placeholder: "请选择执行条件",
                                        options: _vm.ConditionTypeList,
                                        "allow-clear": true,
                                      },
                                      model: {
                                        value: item.type,
                                        callback: function ($$v) {
                                          _vm.$set(item, "type", $$v)
                                        },
                                        expression: "item.type",
                                      },
                                    }),
                                    item.type === "DEVICE_ATTRIBUTE"
                                      ? _c("a-select", {
                                          attrs: {
                                            placeholder: "请选择设备范围",
                                            options: _vm.DeviceRange,
                                          },
                                          model: {
                                            value: item.deviceType,
                                            callback: function ($$v) {
                                              _vm.$set(item, "deviceType", $$v)
                                            },
                                            expression: "item.deviceType",
                                          },
                                        })
                                      : _vm._e(),
                                    item.type === "TIMESPAN"
                                      ? [
                                          _c("a-time-picker", {
                                            model: {
                                              value: item.startTime,
                                              callback: function ($$v) {
                                                _vm.$set(item, "startTime", $$v)
                                              },
                                              expression: "item.startTime",
                                            },
                                          }),
                                          _c("a-time-picker", {
                                            model: {
                                              value: item.endTime,
                                              callback: function ($$v) {
                                                _vm.$set(item, "endTime", $$v)
                                              },
                                              expression: "item.endTime",
                                            },
                                          }),
                                          _c("a-select", {
                                            attrs: {
                                              placeholder: "请选择执行操作",
                                              options:
                                                _vm.ConditionOperatorList,
                                              "allow-clear": true,
                                            },
                                            model: {
                                              value: item.operator,
                                              callback: function ($$v) {
                                                _vm.$set(item, "operator", $$v)
                                              },
                                              expression: "item.operator",
                                            },
                                          }),
                                        ]
                                      : item.type === "DEVICE_ATTRIBUTE"
                                      ? _c(
                                          "LinkageDeviceSelectComponent",
                                          {
                                            attrs: {
                                              model: item,
                                              "device-type-list":
                                                item.deviceType === "OTHER"
                                                  ? _vm.deviceTypeList
                                                  : _vm.deviceTypeFilterList,
                                              "has-trigger-type": false,
                                              "device-mode": "multiple",
                                            },
                                          },
                                          [
                                            _c("a-select", {
                                              attrs: {
                                                placeholder: "请选择执行操作",
                                                options:
                                                  _vm.ConditionOperatorList,
                                                "allow-clear": true,
                                              },
                                              model: {
                                                value: item.operator,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "operator",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.operator",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _c(
                              "a-col",
                              {
                                staticClass: "text-center",
                                attrs: { span: 1 },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "jtl-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeCondition(index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c("li", { staticClass: "linkage-config-item-wrapper" }, [
                    _c(
                      "a",
                      {
                        staticClass: "jtl-link",
                        on: { click: _vm.addNewCondition },
                      },
                      [_vm._v("+ 新增执行条件")]
                    ),
                  ]),
                ],
                2
              ),
            ]
          ),
          _c("br"),
          _c("a-card", { attrs: { bordered: true, title: "执行动作" } }, [
            _c(
              "ul",
              [
                _vm._l(_vm.actionList, function (item, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "linkage-config-item-wrapper" },
                    [
                      _c(
                        "a-row",
                        { attrs: { type: "flex", align: "middle" } },
                        [
                          _c(
                            "a-col",
                            {
                              staticClass: "linkage-config-item-content",
                              attrs: { span: 23 },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "linkage-config-item-title" },
                                [_vm._v("执行动作" + _vm._s(index + 1))]
                              ),
                              _c("br"),
                              _c(
                                "div",
                                { staticClass: "linkage-config-item-nowrap" },
                                [
                                  _c("a-select", {
                                    attrs: {
                                      placeholder: "请选择执行动作",
                                      options: _vm.ActionList,
                                    },
                                    model: {
                                      value: item.type,
                                      callback: function ($$v) {
                                        _vm.$set(item, "type", $$v)
                                      },
                                      expression: "item.type",
                                    },
                                  }),
                                  item.type === "DEVICESERVICE"
                                    ? _c("a-select", {
                                        attrs: {
                                          placeholder: "请选择设备范围",
                                          options: _vm.DeviceRange,
                                        },
                                        model: {
                                          value: item.deviceType,
                                          callback: function ($$v) {
                                            _vm.$set(item, "deviceType", $$v)
                                          },
                                          expression: "item.deviceType",
                                        },
                                      })
                                    : _vm._e(),
                                  item.type === "DEVICESERVICE"
                                    ? _c("LinkageDeviceSelectComponent", {
                                        attrs: {
                                          model: item,
                                          "device-type-list":
                                            item.deviceType === "OTHER"
                                              ? _vm.deviceTypeList
                                              : _vm.deviceTypeFilterList,
                                          "has-trigger-type": false,
                                          "has-method": true,
                                          "device-mode": "multiple",
                                        },
                                      })
                                    : item.type === "ALARM"
                                    ? _c("LinkageAlarmConfigComponent", {
                                        attrs: {
                                          model: item.actionParam,
                                          "device-link-list":
                                            _vm.deviceLinkList,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "a-col",
                            { staticClass: "text-center", attrs: { span: 1 } },
                            [
                              index > 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "jtl-link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeAction(index)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c("li", { staticClass: "linkage-config-item-wrapper" }, [
                  _c(
                    "a",
                    {
                      staticClass: "jtl-link",
                      on: { click: _vm.addNewAction },
                    },
                    [_vm._v("+ 新增执行动作")]
                  ),
                ]),
              ],
              2
            ),
          ]),
          _c("br"),
          _c(
            "jtl-button",
            {
              attrs: {
                type: "primary",
                "click-prop": _vm.saveClick.bind(this),
              },
            },
            [_vm._v("保存")]
          ),
          _vm._v("   "),
          _c("jtl-button", { on: { click: _vm.drawerClose } }, [
            _vm._v("取消"),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }