var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model
    ? _c(
        "div",
        { staticClass: "linkage-alarm-config-component inline-block" },
        [
          _c("a-select", {
            attrs: { placeholder: "请选择告警级别", options: _vm.gradeList },
            model: {
              value: _vm.model.grade,
              callback: function ($$v) {
                _vm.$set(_vm.model, "grade", $$v)
              },
              expression: "model.grade",
            },
          }),
          _c("a-input", {
            staticClass: "operate-value",
            attrs: { placeholder: "告警周期", suffix: "分钟" },
            model: {
              value: _vm.model.cycle,
              callback: function ($$v) {
                _vm.$set(_vm.model, "cycle", $$v)
              },
              expression: "model.cycle",
            },
          }),
          _c("a-input", {
            staticClass: "operate-value",
            attrs: { placeholder: "通知周期", suffix: "分钟" },
            model: {
              value: _vm.model.notifyPeriod,
              callback: function ($$v) {
                _vm.$set(_vm.model, "notifyPeriod", $$v)
              },
              expression: "model.notifyPeriod",
            },
          }),
          _c("a-select", {
            attrs: {
              placeholder: "消息模板",
              options: _vm.templateList,
              mode: "multiple",
            },
            model: {
              value: _vm.model.templateIds,
              callback: function ($$v) {
                _vm.$set(_vm.model, "templateIds", $$v)
              },
              expression: "model.templateIds",
            },
          }),
          _c("SelectUserComponent", {
            staticClass: "inline-block select-user-wrapper",
            attrs: { "has-select-btn": false },
            model: {
              value: _vm.model.noticeList,
              callback: function ($$v) {
                _vm.$set(_vm.model, "noticeList", $$v)
              },
              expression: "model.noticeList",
            },
          }),
          _c("a-select", {
            attrs: {
              placeholder: "请选择指令类型",
              options: _vm.CommandTypeList,
              "allow-clear": true,
            },
            model: {
              value: _vm.model.commandType,
              callback: function ($$v) {
                _vm.$set(_vm.model, "commandType", $$v)
              },
              expression: "model.commandType",
            },
          }),
          _c("jtl-tree-select-component", {
            attrs: {
              "tree-data": _vm.deviceLinkList,
              placeholder: "设备关系分组",
            },
            model: {
              value: _vm.model.tvWallDeviceGroupId,
              callback: function ($$v) {
                _vm.$set(_vm.model, "tvWallDeviceGroupId", $$v)
              },
              expression: "model.tvWallDeviceGroupId",
            },
          }),
          _c(
            "a-select",
            {
              attrs: { placeholder: "是否生成报告", "allow-clear": "" },
              model: {
                value: _vm.model.createReport,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "createReport", $$v)
                },
                expression: "model.createReport",
              },
            },
            [
              _c("a-select-option", { attrs: { value: true } }, [_vm._v("是")]),
              _c("a-select-option", { attrs: { value: false } }, [
                _vm._v("否"),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }