














































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import GroupComponent from '@common-src/mixins/group-component';
import { LinkageConfigEntityModel, LinkageConfigQueryModel } from '@common-src/entity-model/linkage-config-entity';
import LinkageConfigService from '@common-src/service/linkage-config';
import LinkageConfigDrawer from './linkage-config-drawer.vue';
import LinkageConfigHistoryComponent from './linkage-config-history.vue';
import { ModuleType } from '@common-src/enum/module-type.enum';
import calculateByLength from '@common-src/filter/calculate-by-length';

@Component({
    components: {
        'linkage-config-drawer': LinkageConfigDrawer,
        'linkage-config-history-drawer': LinkageConfigHistoryComponent
    }
})
export default class LinkageConfigListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    calculateByLength = calculateByLength;
    LinkageConfigEntityModel = LinkageConfigEntityModel;
    isEdge: boolean = false;
    created() {
        this.initTable({
            service: LinkageConfigService,
            queryModel: new LinkageConfigQueryModel(),
            tableColumns: LinkageConfigEntityModel.getTableColumns()
        });
        this.getList();
        this.isEdge = (JTL.moduleType === ModuleType.EDGE);
    }
    enableClick(model: LinkageConfigEntityModel) {
        const enable = !model.enable;
        LinkageConfigService.enable(model.id, enable).then(() => {
            model.enable = !model.enable;
        });
    }
    detailClick(model: LinkageConfigEntityModel) {
        (this.$refs.linkageConfigDrawer as LinkageConfigDrawer).drawerShow(model);
    }
    triggerClick(model: LinkageConfigEntityModel) {
        LinkageConfigService.trigger(model.id).then(() => {
            this.showMessageSuccess('触发成功');
        });
    }
    logClick(model: LinkageConfigEntityModel) {
        (this.$refs.linkageConfigHistoryDrawer as LinkageConfigHistoryComponent).drawerShow(model);
    }
    refresh() {
        LinkageConfigService.refresh().then(() => {
            this.showMessageSuccess('重新加载成功');
        });
    }
    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as LinkageConfigQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/linkage-config?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/linkage-config');
        }
    }
}

