

































































































import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import TransformComponent from '@common-src/mixins/transform-component';
import AssetService from '@common-src/service/asset';
// import { GroupEntityType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import { DeviceEntityModel, DeviceQueryModel } from '@common-src/entity-model/device-entity';
import { AssetEntityModel } from '@common-src/entity-model/asset-entity';
import { ModuleType } from '@common-src/enum/module-type.enum';

@Component
export default class LinkageDeviceSelectDialog extends Mixins(TransformComponent, TableComponent) {
    // GroupEntityType = GroupEntityType;
    debounceFilterChange: Function = null;

    created() {
        this.debounceFilterChange = _.debounce(this.filterChange, 500);
        this.pageSize = 9999;
        this.isAutoQuery = false;
        this.isLoadMore = false;
        const deviceQueryModel = new DeviceQueryModel();
        deviceQueryModel.forceRoot = false;
        this.initTable({ listFunc: DeviceService.getSimpleDeviceList, queryModel: deviceQueryModel });
    }

    get IsEdge() {
        return JTL.moduleType === ModuleType.EDGE;
    }

    dialogOpen(productId: string, selectDeviceList: Array<string>) {
        this.dialogVisible = true;
        this.rightDataList = _.map(selectDeviceList, item => ({ id: item, displayName: '' }));
        this.listData = [];
        (this.queryModel as DeviceQueryModel).groupIds = null;
        (this.queryModel as DeviceQueryModel).serviceAreaOrName = null;
        (this.queryModel as DeviceQueryModel).typeId = productId;
        this.filterChange();
    }

    filterChange() {
        this.getList().then(() => {
            this.initListData();
        });
    }

    initListData() {
        if (this.rightDataList && this.rightDataList.length > 0) {
            _.forEach(this.rightDataList, item => {
                const leftDataItem = _.find(this.listData, listItem => listItem.id === item.id);
                if (leftDataItem) {
                    leftDataItem.check = true;
                    item.displayName = leftDataItem.displayName;
                    item.serviceArea = leftDataItem.serviceArea;
                }
            });
        }
    }

    dialogOK() {
        this.$emit('dialogOK', _.map(this.rightDataList, item => item.id));
        this.dialogClose();
    }
}
